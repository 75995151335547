@import url(./global.css);
.app {
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  transition: var(--transition);
  background-color: light-dark(var(--color-white-fa), var(--color-black-18));
}
.app .container {
  width: 100%;
  display: flex;
  margin: 0 20px;
  width: var(--wrapper);
}
.app hr {
  width: 100%;
  height: 1px;
  border: none;
  margin-top: 60px;
  transition: var(--transition);
  background-color: var(--border-color);
}
.app .grid {
  width: 100%;
  display: grid;
  gap: 30px 14px;
  grid-template-columns: repeat(12, 1fr);
}
.app .header {
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  border-bottom: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white), var(--color-black-20));
}
.app .header .container {
  margin: 0 20px;
  width: var(--wrapper);
  justify-content: space-between;
}
.app .header .container .flex {
  display: flex;
  align-items: center;
  transition: var(--transition);
  gap: 20px;
}
.app .header .container .flex .logo {
  display: flex;
  max-width: 200px;
  align-items: center;
  text-decoration: none;
}
.app .header .container .flex .logo svg,
.app .header .container .flex .logo img {
  height: 26px;
  display: flex;
  align-items: center;
  pointer-events: none;
  transition: var(--transition);
}
.app .header .container .flex .nav-menu {
  height: 80px;
  display: flex;
  align-items: center;
}
.app .header .container .flex .nav-menu .nav-list {
  gap: 14px;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  list-style: none;
  align-items: center;
}
.app .header .container .flex .nav-menu .nav-list .nav-item {
  height: 80px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .header .container .flex .nav-menu .nav-list .nav-item .nav-link {
  height: 100%;
  display: flex;
  color: inherit;
  padding: 0 6px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  letter-spacing: 0.4px;
  text-decoration: none;
  min-width: -moz-max-content;
  min-width: max-content;
  text-transform: capitalize;
  transition: var(--transition);
  font: normal 14px var(--font-mono);
  color: light-dark(var(--color-black), var(--color-white));
}
.app .header .container .flex .nav-menu .nav-list .nav-item .nav-link:hover {
  text-decoration: underline;
}
.app .header .container .flex .nav-menu .nav-list hr {
  margin: 0;
  width: 1px;
  height: 80px;
  border: none;
  transition: var(--transition);
  background-color: var(--border-color);
}
.app .main {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.app .main .section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.app .main .section .container {
  margin: 0 20px;
  align-items: center;
  flex-direction: column;
}
.app .main .section .container .row-title,
.app .main .section .container .row-action {
  width: 100%;
  display: flex;
  padding: 34px 0;
  align-items: center;
  flex-direction: row;
}
.app .main .section .container .row-title {
  justify-content: flex-start;
}
.app .main .section .container .row-action {
  justify-content: flex-end;
}
.app .main .section .container h1,
.app .main .section .container h2,
.app .main .section .container h3,
.app .main .section .container h4,
.app .main .section .container h5,
.app .main .section .container h6,
.app .main .section .container b {
  margin: 0;
  font-weight: bold;
  text-transform: lowercase;
  font-family: var(--font-mono);
  transition: var(--transition);
  color: light-dark(black, white);
}
.app .main .section .container h1 {
  font-size: 34px;
  text-align: center;
}
.app .main .section .container h2 {
  font-size: 28px;
}
.app .footer {
  display: flex;
  padding: 40px 0;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white), var(--color-black-20));
}
.app .footer .container {
  align-items: center;
  width: var(--wrapper);
  justify-content: center;
}
.app .footer .container p {
  cursor: default;
  pointer-events: none;
  text-transform: uppercase;
  font: normal 10px var(--font-sans);
}
.app .music-card {
  display: flex;
  grid-row: span 4;
  grid-column: span 3;
  flex-direction: column;
}
.app .music-card .music-card-link {
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none;
}
.app .music-card .music-card-cover {
  width: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1/1;
  position: relative;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
}
.app .music-card .music-card-cover .music-cover {
  width: 100%;
  height: 100%;
  display: flex;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  align-items: center;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  justify-content: center;
  transition: var(--transition);
}
.app .music-card .music-card-cover .music-card-songs {
  gap: 4px;
  right: 8px;
  bottom: 8px;
  height: 20px;
  display: flex;
  cursor: pointer;
  padding: 1px 6px;
  position: absolute;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
.app .music-card .music-card-cover .music-card-songs .music-card-songs-icon {
  width: 12px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .music-card .music-card-cover .music-card-songs .music-card-songs-icon img {
  height: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.app .music-card .music-card-cover .music-card-songs .music-card-songs-text {
  letter-spacing: 0.4px;
  color: var(--color-white-ff);
  font: normal 11px var(--font-display);
}
.app .music-card .music-card-cover .music-card-screen {
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  aspect-ratio: 1/1;
  position: absolute;
  transition: var(--transition);
  background-color: transparent;
}
.app .music-card .music-card-caption {
  gap: 6px;
  width: 100%;
  display: flex;
  margin-top: 14px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.app .music-card .music-card-caption .music-card-title {
  width: 100%;
  max-height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  letter-spacing: 0.4px;
  font: bold 15.5px var(--font-display);
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}
.app .music-card .music-card-caption .music-card-type {
  color: #aaaaaa;
  font: normal 11.5px var(--font-mono);
}
.app .music-card-load {
  grid-column: span 3;
  display: flex;
  min-width: 100%;
  flex-direction: column;
}
.app .music-card-load .music-load-cover {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 8px;
  transition: var(--transition);
  border: 1px solid var(--border-color);
}
.app .music-card-load .music-load-details {
  gap: 8px;
  display: flex;
  margin-top: 12px;
  flex-direction: column;
  align-items: flex-start;
}
.app .music-card-load .music-load-details .music-load-title {
  width: 140px;
  height: 14px;
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--border-color);
}
.app .music-card-load .music-load-details .music-load-type {
  width: 200px;
  height: 12px;
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--border-color);
}
.app .background {
  inset: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
}
.app .background .image {
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  filter: blur(50px);
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  backface-visibility: hidden;
}
.app .background .noise {
  top: 0;
  z-index: 3;
  content: "";
  width: 100%;
  height: 100vh;
  opacity: 0.08;
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PScwIDAgNTEyIDUxMicgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KICA8ZmlsdGVyIGlkPSdub2lzZUZpbHRlcic+CiAgICA8ZmVUdXJidWxlbmNlIAogICAgICB0eXBlPSdmcmFjdGFsTm9pc2UnIAogICAgICBiYXNlRnJlcXVlbmN5PScwLjcnCiAgICAgIG51bU9jdGF2ZXM9JzMnIAogICAgICBzdGl0Y2hUaWxlcz0nc3RpdGNoJy8+CiAgICA8ZmVDb2xvck1hdHJpeCBpbj0idHVyYnVsZW5jZSIgdHlwZT0ic2F0dXJhdGUiIHZhbHVlcz0iMCIvPgoKICAgIDxmZUNvbXBvbmVudFRyYW5zZmVyPgogICAgICA8ZmVGdW5jUiB0eXBlPSJkaXNjcmV0ZSIgdGFibGVWYWx1ZXM9IjAgMSIgLz4KICAgICAgPGZlRnVuY0cgdHlwZT0iZGlzY3JldGUiIHRhYmxlVmFsdWVzPSIwIDEiIC8+CiAgICAgIDxmZUZ1bmNCIHR5cGU9ImRpc2NyZXRlIiB0YWJsZVZhbHVlcz0iMCAxIiAvPgogICAgPC9mZUNvbXBvbmVudFRyYW5zZmVyPgogIDwvZmlsdGVyPgogIAogIDxyZWN0IHdpZHRoPScxMDAlJyBoZWlnaHQ9JzEwMCUnIGZpbHRlcj0ndXJsKCNub2lzZUZpbHRlciknLz4KPC9zdmc+);
  background-size: 512px 512px;
  background-repeat: repeat;
  mix-blend-mode: overlay;
}
.app .background .glass {
  inset: 0;
  z-index: 2;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(27, 27, 27, 0.168627451);
  box-shadow: 0 0 80px 50px rgba(24, 24, 24, 0.1019607843) inset;
}
.app .music-release-container {
  inset: 0;
  z-index: 3;
  width: 100%;
  display: flex;
  position: fixed;
  overflow-x: auto;
  min-height: 100vh;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.app .music-release-container .music-release-content {
  z-index: 20;
  width: 380px;
  display: flex;
  margin-top: 24px;
  margin-bottom: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.21);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--color-black);
}
.app .music-release-container .music-release-content .music-release-cover {
  width: 100%;
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black-25);
  font: bold 18px var(--font-mono);
  position: relative;
  aspect-ratio: 1/1;
}
.app .music-release-container .music-release-content .music-release-cover img {
  z-index: 21;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
  -o-object-position: center top;
     object-position: center top;
}
.app .music-release-container .music-release-content .music-release-cover .covers-box {
  gap: 1px;
  top: 100%;
  left: 0px;
  right: 0px;
  z-index: 23;
  width: 100%;
  height: 90px;
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  flex-wrap: nowrap;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transition: var(--transition);
  background-color: rgba(8, 8, 8, 0.3019607843);
}
.app .music-release-container .music-release-content .music-release-cover .covers-box .cover-box {
  min-width: 90px;
  max-width: 90px;
  min-height: 90px;
  max-height: 90px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.app .music-release-container .music-release-content .music-release-cover .covers-box .cover-box img {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
  -o-object-position: center top;
     object-position: center top;
}
.app .music-release-container .music-release-content .music-release-cover .covers-box::-webkit-scrollbar {
  background-color: #2c2c2c;
  height: 4px;
  width: 0px;
}
.app .music-release-container .music-release-content .music-release-cover .covers-box::-webkit-scrollbar-thumb {
  background-color: #9f9f9f;
  border-radius: 4px;
}
.app .music-release-container .music-release-content .music-release-cover .covers-box.show {
  top: calc(100% - 90px);
}
.app .music-release-container .music-release-content .music-release-cover .teaser-box {
  inset: 0;
  z-index: 22;
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 4px;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: var(--transition);
}
.app .music-release-container .music-release-content .music-release-box {
  gap: 8px;
  display: flex;
  cursor: default;
  padding: 30px 20px;
  align-items: center;
  flex-direction: column;
}
.app .music-release-container .music-release-content .music-release-box .title,
.app .music-release-container .music-release-content .music-release-box .subtitle {
  text-wrap: balance;
  text-align: center;
  letter-spacing: 0.4px;
  text-transform: capitalize !important;
  font-family: var(--font-display) !important;
}
.app .music-release-container .music-release-content .music-release-box .title {
  color: white;
  font-size: 22px;
  margin: 0 0 10px;
  font-weight: bold;
}
.app .music-release-container .music-release-content .music-release-box .subtitle {
  font-size: 13px;
  color: #aaaaaa;
  font-weight: normal;
}
.app .music-release-container .music-release-content .music-release-date {
  padding-bottom: 24px;
  display: flex;
  cursor: default;
  margin-top: -14px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.app .music-release-container .music-release-content .music-release-date .available {
  margin-bottom: 14px;
  letter-spacing: 0.4px;
  color: var(--color-white);
  font: normal 12px var(--font-display);
}
.app .music-release-container .music-release-content .music-release-date .date-string {
  color: #00ffac;
  margin-bottom: 20px;
  font: normal 12px var(--font-mono);
}
.app .music-release-container .music-release-content .music-release-date .date-string::first-letter {
  text-transform: uppercase;
}
.app .music-release-container .music-release-content .music-release-date .music-release-countdown {
  width: calc(100% - 48px);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.app .music-release-container .music-release-content .music-release-date .music-release-countdown .countdown-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.app .music-release-container .music-release-content .music-release-date .music-release-countdown .countdown-item .countdown-item-number {
  color: white;
  font: bold 20px var(--font-mono);
}
.app .music-release-container .music-release-content .music-release-date .music-release-countdown .countdown-item .countdown-item-letter {
  color: gainsboro;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  font: normal 10px var(--font-display);
}
.app .music-release-container .music-release-content .music-release-delete {
  display: flex;
  cursor: default;
  margin-top: -8px;
  padding: 0 0 24px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.app .music-release-container .music-release-content .music-release-delete .unavailable {
  margin-bottom: 10px;
  letter-spacing: 0.4px;
  color: var(--color-white);
  font: normal 12px var(--font-display);
}
.app .music-release-container .music-release-content .music-release-delete .removed {
  color: #ff4040;
  font: normal 12px var(--font-mono);
}
.app .music-release-container .music-release-content .music-release-delete .removed::first-letter {
  text-transform: uppercase;
}
.app .music-release-container .music-release-content .music-release-relative {
  z-index: 24;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -12px;
}
.app .music-release-container .music-release-content .music-release-relative .music-release-absolute {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid rgba(53, 53, 53, 0.5019607843);
}
.app .music-release-container .music-release-content .music-release-links {
  z-index: 22;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border-top: none !important;
}
.app .music-release-container .music-release-content .music-release-links a {
  width: 100%;
  color: white;
  cursor: pointer;
  text-decoration: none;
}
.app .music-release-container .music-release-content .music-release-links .item-music {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  transition: var(--transition);
}
.app .music-release-container .music-release-content .music-release-links .item-music .item-music-logo {
  width: 50%;
  display: flex;
  padding: 0 24px;
  align-items: center;
  justify-content: flex-start;
}
.app .music-release-container .music-release-content .music-release-links .item-music .item-music-logo img {
  max-width: 140px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.app .music-release-container .music-release-content .music-release-links .item-music .item-music-action {
  width: 50%;
  display: flex;
  padding: 0 24px;
  align-items: center;
  pointer-events: none;
  justify-content: flex-end;
}
.app .music-release-container .music-release-content .music-release-links .item-music .item-music-action .icon-item {
  gap: 8px;
  display: flex;
  align-items: center;
}
.app .music-release-container .music-release-content .music-release-links .item-music .item-music-action .icon-item .icon-svg {
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: relative;
  align-items: center;
  pointer-events: none;
  justify-content: center;
}
.app .music-release-container .music-release-content .music-release-links .item-music .item-music-action .icon-item .icon-svg svg {
  z-index: 2;
  color: white;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  transition: var(--transition);
}
.app .music-release-container .music-release-content .music-release-links .item-music .item-music-action .icon-item .icon-svg .icon-svg-shadow {
  width: 0;
  height: 0;
  z-index: 1;
  position: absolute;
  transition: var(--transition);
}
.app .music-release-container .music-release-content .music-release-links .item-music .item-music-action .icon-item .icon-text {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  letter-spacing: 0.4px;
  color: var(--color-white);
  text-transform: uppercase;
  transition: var(--transition);
  font: normal 12px var(--font-body);
}
.app .music-release-container .music-release-content .music-release-links .item-music:first-child {
  border-top: 1px solid var(--color-black-35);
}
.app .music-release-container .music-release-content .music-release-links .item-music:hover {
  background-color: var(--color-black-28);
}
.app .music-release-container .music-release-content .music-release-links .item-music:hover .item-music-action .icon-item .icon-svg svg {
  color: #00c4ff;
}
.app .music-release-container .music-release-content .music-release-links .item-music:hover .item-music-action .icon-item .icon-svg .icon-svg-shadow {
  box-shadow: 0 0 14px 3px #00c4ff;
}
.app .music-release-container .music-release-content .music-release-links .item-music:hover .item-music-action .icon-item .icon-text {
  color: #00c4ff;
  text-shadow: 0 0 12px #00c4ff;
}
.app .music-release-container .logo {
  width: 100%;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.app .music-release-container .logo a {
  height: 100%;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  justify-content: center;
}
.app .music-release-container .logo a svg {
  height: 22px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  transition: var(--transition);
}
.app .banner-search {
  gap: 40px;
  width: 100%;
  height: 380px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  margin-bottom: 30px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: url(../images/img32.jpg);
  background-position: center top;
  background-size: cover;
}
.app .banner-search .blur {
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.app .banner-search h1 {
  z-index: 2;
  width: 50%;
  text-wrap: balance;
  color: white !important;
  text-shadow: 1px 1px 1px black;
  text-transform: none !important;
  font: bold 28px var(--font-display) !important;
}
.app .banner-search form {
  z-index: 2;
  width: 380px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .banner-search form input {
  width: calc(100% - 40px - 60px);
  height: 38px;
  color: black;
  padding-left: 14px;
  letter-spacing: 0.6px;
  border-radius: 8px 0 0 8px;
  border: 1px solid #cdcdcd;
  font: normal 14.5px var(--font-display);
  background-color: var(--color-white-fa);
}
.app .banner-search form input:focus-visible {
  outline: none;
}
.app .banner-search form button[type=reset] {
  right: 60px;
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  position: absolute;
  align-items: center;
  justify-content: center;
  color: #aaaaaa;
  background: none;
  border: none;
}
.app .banner-search form button[type=submit] {
  width: 40px;
  min-width: 45px;
  height: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: black;
  border: 1px solid black;
  border-radius: 0 8px 8px 0;
  color: white;
}
.app .no-found {
  width: 100%;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  background-color: light-dark(var(--color-white-fa), var(--color-black-18));
}
.app .no-found .wrapper {
  gap: 30px;
  display: flex;
  align-items: center;
  width: var(--wrapper);
  flex-direction: column;
  justify-content: center;
}
.app .no-found .wrapper .title-no-found {
  color: var(--border-color);
  font: bold 400px var(--font-mono);
  pointer-events: none;
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  z-index: 1;
}
.app .no-found .wrapper .icon-no-found {
  font-size: 80px;
  pointer-events: none;
  z-index: 2;
}
.app .no-found .wrapper .text-no-found {
  text-shadow: 2px 2px 10px var(--color-black-20);
  font: normal 16px var(--font-display);
  letter-spacing: 0.4px;
  text-wrap: balance;
  text-align: center;
  z-index: 2;
}
.app .no-found .wrapper .text-no-found br {
  display: none;
}
.app .no-found .wrapper a {
  z-index: 2;
}
.app .app {
  padding: 30px 0px 100px;
}
.app .login-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .login-page .form {
  gap: 14px;
  width: 340px;
  display: flex;
  padding: 40px 20px;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  border: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
}
.app .login-page .form .block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .login-page .form .block .title {
  margin-bottom: 20px;
  font: bold 20px var(--font-mono);
}
.app .login-page .form .block input {
  width: 100%;
  height: 34px;
  padding: 0px 14px;
  border-radius: 4px;
  font: normal 12px var(--font-mono);
  border: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-fa), var(--color-black-18));
}
.app .login-page .form .block .primary-button {
  color: white;
  min-width: 100%;
  background-color: black;
}

.app.dark {
  background-color: var(--background-color);
}
.app.dark .header {
  background-color: var(--color-black-20);
}
.app.dark .header .container .flex .icon-button {
  color: white;
}
.app.dark .main .section .container h1,
.app.dark .main .section .container h2,
.app.dark .main .section .container h3,
.app.dark .main .section .container h4,
.app.dark .main .section .container h5,
.app.dark .main .section .container h6,
.app.dark .main .section .container b {
  color: white;
}
.app.dark .footer {
  color: white;
  background-color: var(--color-black);
}
.app.dark .music-card {
  background-color: var(--color-black);
}
.app.dark .music-card-load {
  animation: var(--loading-dark);
}

@media (prefers-color-scheme: light) {
  .music-card-load .music-load-cover,
  .music-card-load .music-load-title,
  .music-card-load .music-load-type {
    animation: var(--loading);
  }
}
@media (prefers-color-scheme: dark) {
  .music-card-load .music-load-cover,
  .music-card-load .music-load-title,
  .music-card-load .music-load-type {
    animation: var(--loading-dark) !important;
  }
}
@media screen and (max-width: 64em) {
  .banner-search h1 {
    width: 100% !important;
  }
  .music-card,
  .music-card-load {
    grid-column: span 6 !important;
  }
}
@media screen and (max-width: 40em) {
  .banner-search h1 {
    font-size: 20px !important;
  }
  .banner-search form {
    width: calc(100% - 60px) !important;
  }
  .music-card,
  .music-card-load {
    grid-column: span 12 !important;
  }
  .app .grid {
    flex-direction: column !important;
  }
  .app .music-card,
  .app .music-card-load {
    width: 100%;
  }
  .app .no-found {
    width: 100%;
  }
  .app .no-found .wrapper {
    margin: 0 20px;
    width: 100% !important;
  }
  .app .no-found .wrapper .title-no-found {
    font-size: 200px !important;
  }
  .app .no-found .wrapper .text-no-found {
    font-size: 13px !important;
    line-height: 28px !important;
  }
  .music-release-container .music-release-content .music-release-cover .covers-box::-webkit-scrollbar {
    height: 0px !important;
  }
}
.sign-out {
  left: 20px;
  bottom: 20px;
  width: 34px;
  height: 34px;
  position: fixed;
  cursor: pointer;
  border: 1px solid var(--border-color);
  background-color: var(--color-black-20);
}

.button {
  border: none;
  display: flex;
  cursor: pointer;
  padding: 0 16px;
  background: none;
  border-radius: 4px;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}

.icon-button {
  width: 34px;
  height: 34px;
  font-size: 20px;
  position: relative;
  color: light-dark(var(--color-black), var(--color-white));
}
.icon-button .bag-count {
  top: -2px;
  right: -4px;
  width: 18px;
  height: 18px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
  color: var(--color-white);
  font: normal 10px var(--font-mono);
  background-color: var(--primary-color);
}
.icon-button .icon-two {
  position: relative;
}
.icon-button .icon-two svg {
  position: absolute;
}
.icon-button .icon-two svg:nth-child(1) {
  z-index: 1;
}
.icon-button .icon-two svg:nth-child(2) {
  z-index: 2;
  margin-left: -1px;
  margin-top: 10px;
}

.primary-button {
  height: 40px;
  text-decoration: none;
  max-width: -moz-max-content;
  max-width: max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  color: var(--color-white);
  line-height: 40px !important;
  font: normal 14px var(--font-mono);
  background-color: var(--color-black);
  border: 1px solid var(--border-color);
}
.primary-button:hover {
  border-color: var(--primary-color);
  background-color: var(--color-black-28);
}
.primary-button[disabled] {
  cursor: no-drop;
  color: var(--inactive-color);
  border-color: var(--border-color);
  background-color: var(--color-grey);
}/*# sourceMappingURL=app.css.map */