.app {
    width: 100%;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    transition: var(--transition);
    background-color: light-dark(var(--color-white-fa), var(--color-black-18));

    // ---- ---- clases ---- ---- //

    .container {
        width: 100%;
        display: flex;
        margin: 0 20px;
        width: var(--wrapper);
    }

    hr {
        width: 100%;
        height: 1px;
        border: none;
        margin-top: 60px;
        transition: var(--transition);
        background-color: var(--border-color);
    }

    .grid {
        width: 100%;
        display: grid;
        gap: 30px 14px;
        grid-template-columns: repeat(12, 1fr);
        // display: flex;
        // align-items: center;
        // justify-content: flex-end;
        // flex-flow: row-reverse wrap-reverse;
    }

    // ---- ---- estructura ---- ---- //

    .header {
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
        border-bottom: 1px solid var(--border-color);
        background-color: light-dark(var(--color-white), var(--color-black-20));

        .container {
            margin: 0 20px;
            width: var(--wrapper);
            justify-content: space-between;

            .flex {
                display: flex;
                align-items: center;
                transition: var(--transition);
                gap: 20px;

                .logo {
                    display: flex;
                    max-width: 200px;
                    align-items: center;
                    text-decoration: none;

                    svg,
                    img {
                        height: 26px;
                        display: flex;
                        align-items: center;
                        pointer-events: none;
                        transition: var(--transition);
                    }
                }

                .nav-menu {
                    height: 80px;
                    display: flex;
                    align-items: center;

                    .nav-list {
                        gap: 14px;
                        margin: 0;
                        padding: 0;
                        height: 100%;
                        display: flex;
                        list-style: none;
                        align-items: center;

                        .nav-item {
                            height: 80px;
                            display: flex;
                            position: relative;
                            align-items: center;
                            justify-content: center;

                            .nav-link {
                                height: 100%;
                                display: flex;
                                color: inherit;
                                padding: 0 6px;
                                user-select: none;
                                align-items: center;
                                letter-spacing: .4px;
                                text-decoration: none;
                                min-width: max-content;
                                text-transform: capitalize;
                                transition: var(--transition);
                                font: normal 14px var(--font-mono);
                                color: light-dark(var(--color-black), var(--color-white));

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }

                        hr {
                            margin: 0;
                            width: 1px;
                            height: 80px;
                            border: none;
                            transition: var(--transition);
                            background-color: var(--border-color);
                        }
                    }
                }
            }
        }
    }

    .main {
        width: 100%;
        display: flex;
        overflow: hidden;
        align-items: center;
        flex-direction: column;
        // margin-bottom: 80px;
        // margin-top: 80px;

        .section {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;

            .container {
                margin: 0 20px;
                align-items: center;
                flex-direction: column;

                .row-title,
                .row-action {
                    width: 100%;
                    display: flex;
                    padding: 34px 0;
                    align-items: center;
                    flex-direction: row;
                }

                .row-title {
                    justify-content: flex-start;
                }

                .row-action {
                    justify-content: flex-end;
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                b {
                    margin: 0;
                    font-weight: bold;
                    text-transform: lowercase;
                    font-family: var(--font-mono);
                    transition: var(--transition);
                    color: light-dark(black, white);
                }

                h1 {
                    font-size: 34px;
                    text-align: center;
                }

                h2 {
                    font-size: 28px;
                }
            }
        }
    }

    .footer {
        display: flex;
        padding: 40px 0;
        // margin-top: 34px;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        background-color: light-dark(var(--color-white), var(--color-black-20));

        .container {
            align-items: center;
            width: var(--wrapper);
            justify-content: center;

            p {
                cursor: default;
                pointer-events: none;
                text-transform: uppercase;
                font: normal 10px var(--font-sans);
            }
        }
    }

    // ---- ---- componentes ---- ---- //

    .music-card {
        display: flex;
        grid-row: span 4;
        grid-column: span 3;
        flex-direction: column;

        .music-card-link {
            width: 100%;
            height: 100%;
            color: inherit;
            text-decoration: none;
        }

        .music-card-cover {
            width: 100%;
            display: flex;
            overflow: hidden;
            aspect-ratio: 1/1;
            position: relative;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--border-color);

            .music-cover {
                width: 100%;
                height: 100%;
                display: flex;
                aspect-ratio: 1/1;
                object-fit: cover;
                align-items: center;
                pointer-events: none;
                object-position: center;
                justify-content: center;
                transition: var(--transition);
            }

            .music-card-songs {
                gap: 4px;
                right: 8px;
                bottom: 8px;
                height: 20px;
                display: flex;
                cursor: pointer;
                padding: 1px 6px;
                position: absolute;
                border-radius: 4px;
                align-items: center;
                justify-content: center;

                .music-card-songs-icon {
                    width: 12px;
                    height: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        height: 16px;
                        user-select: none;
                        pointer-events: none;
                    }
                }

                .music-card-songs-text {
                    letter-spacing: .4px;
                    color: var(--color-white-ff);
                    font: normal 11px var(--font-display);
                }
            }

            .music-card-screen {
                top: 0px;
                left: 0px;
                z-index: 2;
                width: 100%;
                height: 100%;
                display: flex;
                aspect-ratio: 1/1;
                position: absolute;
                transition: var(--transition);
                background-color: transparent;
            }
        }

        .music-card-caption {
            gap: 6px;
            width: 100%;
            display: flex;
            margin-top: 14px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .music-card-title {
                width: 100%;
                max-height: 40px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                letter-spacing: .4px;
                font: bold 15.5px var(--font-display);
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                white-space: normal;
            }

            .music-card-type {
                color: #aaaaaa;
                font: normal 11.5px var(--font-mono);
            }
        }
    }

    .music-card-load {
        grid-column: span 3;
        display: flex;
        min-width: 100%;
        flex-direction: column;

        .music-load-cover {
            width: 100%;
            aspect-ratio: 1/1;
            border-radius: 8px;
            transition: var(--transition);
            border: 1px solid var(--border-color);
        }

        .music-load-details {
            gap: 8px;
            display: flex;
            margin-top: 12px;
            flex-direction: column;
            align-items: flex-start;

            .music-load-title {
                width: 140px;
                height: 14px;
                display: flex;
                border-radius: 4px;
                border: 1px solid var(--border-color);
            }

            .music-load-type {
                width: 200px;
                height: 12px;
                display: flex;
                border-radius: 4px;
                border: 1px solid var(--border-color);
            }
        }
    }

    .background {
        inset: 0;
        width: 100%;
        height: 100vh;
        position: fixed;
        // background-color: steelblue;

        .image {
            inset: 0;
            z-index: 1;
            width: 100%;
            height: 100vh;
            filter: blur(50px);
            position: absolute;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            backface-visibility: hidden;
        }

        .noise {
            top: 0;
            z-index: 3;
            content: "";
            width: 100%;
            height: 100vh;
            opacity: 0.08;
            position: absolute;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PScwIDAgNTEyIDUxMicgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KICA8ZmlsdGVyIGlkPSdub2lzZUZpbHRlcic+CiAgICA8ZmVUdXJidWxlbmNlIAogICAgICB0eXBlPSdmcmFjdGFsTm9pc2UnIAogICAgICBiYXNlRnJlcXVlbmN5PScwLjcnCiAgICAgIG51bU9jdGF2ZXM9JzMnIAogICAgICBzdGl0Y2hUaWxlcz0nc3RpdGNoJy8+CiAgICA8ZmVDb2xvck1hdHJpeCBpbj0idHVyYnVsZW5jZSIgdHlwZT0ic2F0dXJhdGUiIHZhbHVlcz0iMCIvPgoKICAgIDxmZUNvbXBvbmVudFRyYW5zZmVyPgogICAgICA8ZmVGdW5jUiB0eXBlPSJkaXNjcmV0ZSIgdGFibGVWYWx1ZXM9IjAgMSIgLz4KICAgICAgPGZlRnVuY0cgdHlwZT0iZGlzY3JldGUiIHRhYmxlVmFsdWVzPSIwIDEiIC8+CiAgICAgIDxmZUZ1bmNCIHR5cGU9ImRpc2NyZXRlIiB0YWJsZVZhbHVlcz0iMCAxIiAvPgogICAgPC9mZUNvbXBvbmVudFRyYW5zZmVyPgogIDwvZmlsdGVyPgogIAogIDxyZWN0IHdpZHRoPScxMDAlJyBoZWlnaHQ9JzEwMCUnIGZpbHRlcj0ndXJsKCNub2lzZUZpbHRlciknLz4KPC9zdmc+);
            background-size: 512px 512px;
            background-repeat: repeat;
            mix-blend-mode: overlay;
        }

        .glass {
            inset: 0;
            z-index: 2;
            width: 100%;
            height: 100vh;
            position: fixed;
            background-color: #1b1b1b2b;
            box-shadow: 0 0 80px 50px #1818181a inset;
        }
    }

    .music-release-container {
        inset: 0;
        z-index: 3;
        width: 100%;
        display: flex;
        position: fixed;
        overflow-x: auto;
        min-height: 100vh;
        position: relative;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;

        .music-release-content {
            z-index: 20;
            width: 380px;
            display: flex;
            margin-top: 24px;
            margin-bottom: 100px;
            // margin: 24px 0 75px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, .12), 0 3px 6px rgba(0, 0, 0, .21);
            flex-direction: column;
            justify-content: flex-start;
            background-color: var(--color-black);

            .music-release-cover {
                width: 100%;
                display: flex;
                overflow: hidden;
                user-select: none;
                align-items: center;
                justify-content: center;
                background-color: var(--color-black-25);
                font: bold 18px var(--font-mono);
                position: relative;
                aspect-ratio: 1/1;

                img {
                    z-index: 21;
                    width: 100%;
                    height: 100%;
                    user-select: none;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                    pointer-events: none;
                    object-position: center top;
                }

                .covers-box {
                    gap: 1px;
                    top: 100%;
                    left: 0px;
                    right: 0px;
                    z-index: 23;
                    width: 100%;
                    height: 90px;
                    display: flex;
                    overflow: hidden;
                    overflow-x: auto;
                    flex-wrap: nowrap;
                    position: absolute;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    transition: var(--transition);
                    background-color: #0808084d;

                    .cover-box {
                        min-width: 90px;
                        max-width: 90px;
                        min-height: 90px;
                        max-height: 90px;
                        display: flex;
                        cursor: pointer;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 100%;
                            height: 100%;
                            user-select: none;
                            aspect-ratio: 1/1;
                            object-fit: cover;
                            pointer-events: none;
                            object-position: center top;
                        }
                    }

                    &::-webkit-scrollbar {
                        background-color: #2c2c2c;
                        height: 4px;
                        width: 0px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #9f9f9f;
                        border-radius: 4px;
                    }
                }

                .covers-box.show {
                    top: calc(100% - 90px);
                }

                .teaser-box {
                    inset: 0;
                    z-index: 22;
                    display: flex;
                    cursor: pointer;
                    user-select: none;
                    border-radius: 4px;
                    position: absolute;
                    align-items: center;
                    justify-content: center;
                    background: transparent;
                    transition: var(--transition);
                }
            }

            .music-release-box {
                gap: 8px;
                display: flex;
                cursor: default;
                padding: 30px 20px;
                align-items: center;
                flex-direction: column;

                .title,
                .subtitle {
                    text-wrap: balance;
                    text-align: center;
                    letter-spacing: .4px;
                    text-transform: capitalize !important;
                    font-family: var(--font-display) !important;
                }

                .title {
                    color: white;
                    font-size: 22px;
                    margin: 0 0 10px;
                    font-weight: bold;
                }

                .subtitle {
                    font-size: 13px;
                    color: #aaaaaa;
                    font-weight: normal;
                }
            }

            .music-release-date {
                padding-bottom: 24px;
                display: flex;
                cursor: default;
                margin-top: -14px;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .available {
                    margin-bottom: 14px;
                    letter-spacing: .4px;
                    color: var(--color-white);
                    font: normal 12px var(--font-display);
                }

                .date-string {
                    color: #00ffac;
                    margin-bottom: 20px;
                    font: normal 12px var(--font-mono);

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }

                .music-release-countdown {
                    width: calc(100% - 48px);
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .countdown-item {
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        .countdown-item-number {
                            color: white;
                            font: bold 20px var(--font-mono);
                        }

                        .countdown-item-letter {
                            color: gainsboro;
                            letter-spacing: .4px;
                            text-transform: capitalize;
                            font: normal 10px var(--font-display);
                        }
                    }
                }
            }

            .music-release-delete {
                display: flex;
                cursor: default;
                margin-top: -8px;
                padding: 0 0 24px;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .unavailable {
                    margin-bottom: 10px;
                    letter-spacing: .4px;
                    color: var(--color-white);
                    font: normal 12px var(--font-display);
                }

                .removed {
                    color: #ff4040;
                    font: normal 12px var(--font-mono);

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
            }

            .music-release-relative {
                z-index: 24;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: -12px;

                .music-release-absolute {
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-top: 12px solid #35353580;
                }
            }

            .music-release-links {
                z-index: 22;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: flex-start;
                border-top: none !important;

                a {
                    width: 100%;
                    color: white;
                    cursor: pointer;
                    text-decoration: none;
                }

                .item-music {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    transition: var(--transition);

                    .item-music-logo {
                        width: 50%;
                        display: flex;
                        padding: 0 24px;
                        align-items: center;
                        justify-content: flex-start;

                        img {
                            max-width: 140px;
                            user-select: none;
                            pointer-events: none;
                        }
                    }

                    .item-music-action {
                        width: 50%;
                        display: flex;
                        padding: 0 24px;
                        align-items: center;
                        pointer-events: none;
                        justify-content: flex-end;

                        .icon-item {
                            gap: 8px;
                            display: flex;
                            align-items: center;

                            .icon-svg {
                                display: flex;
                                user-select: none;
                                position: relative;
                                align-items: center;
                                pointer-events: none;
                                justify-content: center;

                                svg {
                                    z-index: 2;
                                    color: white;
                                    user-select: none;
                                    pointer-events: none;
                                    transition: var(--transition);
                                }

                                .icon-svg-shadow {
                                    width: 0;
                                    height: 0;
                                    z-index: 1;
                                    position: absolute;
                                    transition: var(--transition);
                                }
                            }

                            .icon-text {
                                user-select: none;
                                letter-spacing: .4px;
                                color: var(--color-white);
                                text-transform: uppercase;
                                transition: var(--transition);
                                font: normal 12px var(--font-body);
                            }
                        }
                    }

                    &:first-child {
                        border-top: 1px solid var(--color-black-35);
                    }

                    &:hover {
                        background-color: var(--color-black-28);

                        .item-music-action {

                            .icon-item {

                                .icon-svg {

                                    svg {
                                        color: #00c4ff;
                                    }

                                    .icon-svg-shadow {
                                        box-shadow: 0 0 14px 3px #00c4ff;
                                    }
                                }

                                .icon-text {
                                    color: #00c4ff;
                                    text-shadow: 0 0 12px #00c4ff;
                                }
                            }
                        }
                    }
                }
            }
        }

        .logo {
            width: 100%;
            display: flex;
            user-select: none;
            align-items: center;
            justify-content: center;
            margin-bottom: 50px;

            a {
                height: 100%;
                display: flex;
                user-select: none;
                align-items: center;
                justify-content: center;

                svg {
                    height: 22px;
                    user-select: none;
                    pointer-events: none;
                    transition: var(--transition);
                }
            }
        }
    }

    // ---- ---- pages ---- ---- // 

    .banner-search {
        gap: 40px;
        width: 100%;
        height: 380px;
        display: flex;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        margin-bottom: 30px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-image: url(../images/img32.jpg);
        background-position: center top;
        background-size: cover;
        // background-color: var(--accent-color);

        .blur {
            z-index: 1;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            position: absolute;
            // background: #08080880;
            background: transparent;
            backdrop-filter: blur(5px);
        }

        h1 {
            z-index: 2;
            width: 50%;
            text-wrap: balance;
            color: white !important;
            text-shadow: 1px 1px 1px black;
            text-transform: none !important;
            font: bold 28px var(--font-display) !important;
        }

        form {
            z-index: 2;
            width: 380px;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;

            input {
                width: calc(100% - 40px - 60px);
                height: 38px;
                color: black;
                padding-left: 14px;
                letter-spacing: .6px;
                border-radius: 8px 0 0 8px;
                border: 1px solid #cdcdcd;
                font: normal 14.5px var(--font-display);
                background-color: var(--color-white-fa);

                &:focus-visible {
                    outline: none;
                }
            }

            button[type="reset"] {
                right: 60px;
                width: 40px;
                height: 40px;
                display: flex;
                cursor: pointer;
                position: absolute;
                align-items: center;
                justify-content: center;
                color: #aaaaaa;
                background: none;
                border: none;
            }

            button[type="submit"] {
                width: 40px;
                min-width: 45px;
                height: 40px;
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                background-color: black;
                border: 1px solid black;
                border-radius: 0 8px 8px 0;
                color: white;
            }
        }
    }

    .no-found {
        width: 100%;
        display: flex;
        min-height: 100vh;
        justify-content: center;
        background-color: light-dark(var(--color-white-fa), var(--color-black-18));

        .wrapper {
            gap: 30px;
            display: flex;
            align-items: center;
            width: var(--wrapper);
            flex-direction: column;
            justify-content: center;

            .title-no-found {
                color: var(--border-color);
                font: bold 400px var(--font-mono);
                pointer-events: none;
                position: absolute;
                user-select: none;
                z-index: 1;
            }

            .icon-no-found {
                font-size: 80px;
                pointer-events: none;
                z-index: 2;
            }

            .text-no-found {
                text-shadow: 2px 2px 10px var(--color-black-20);
                font: normal 16px var(--font-display);
                letter-spacing: .4px;
                text-wrap: balance;
                text-align: center;
                z-index: 2;

                br {
                    display: none;
                }
            }

            a {
                z-index: 2;
            }
        }
    }

    .app {
        padding: 30px 0px 100px;
    }

    .login-page {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: rosybrown;

        .form {
            gap: 14px;
            width: 340px;
            display: flex;
            padding: 40px 20px;
            border-radius: 8px;
            align-items: center;
            flex-direction: column;
            border: 1px solid var(--border-color);
            background-color: light-dark(var(--color-white-ff), var(--color-black-20));

            .block {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .title {
                    margin-bottom: 20px;
                    font: bold 20px var(--font-mono);
                }

                input {
                    width: 100%;
                    height: 34px;
                    padding: 0px 14px;
                    border-radius: 4px;
                    font: normal 12px var(--font-mono);
                    border: 1px solid var(--border-color);
                    background-color: light-dark(var(--color-white-fa), var(--color-black-18));
                }

                .primary-button {
                    color: white;
                    min-width: 100%;
                    background-color: black;
                }
            }
        }
    }
}

.app.dark {
    background-color: var(--background-color);

    .header {
        background-color: var(--color-black-20);

        .container {

            .flex {

                .icon-button {
                    color: white;
                }
            }
        }
    }

    .main {

        .section {

            .container {

                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                b {
                    color: white;
                }
            }
        }
    }

    .footer {
        color: white;
        background-color: var(--color-black);
    }

    .music-card {
        background-color: var(--color-black);
    }

    .music-card-load {
        animation: var(--loading-dark);
    }
}

@media (prefers-color-scheme: light) {
    .music-card-load {

        .music-load-cover,
        .music-load-title,
        .music-load-type {
            animation: var(--loading);
        }
    }
}

@media (prefers-color-scheme: dark) {
    .music-card-load {

        .music-load-cover,
        .music-load-title,
        .music-load-type {
            animation: var(--loading-dark) !important;
        }
    }
}

@media screen and (max-width: 64em) {

    .banner-search {

        h1 {
            width: 100% !important;
        }
    }

    .music-card,
    .music-card-load {
        grid-column: span 6 !important;
    }
}

@media screen and (max-width: 40em) {

    .banner-search {

        h1 {
            font-size: 20px !important;
        }

        form {
            width: calc(100% - 60px) !important;
        }
    }

    .music-card,
    .music-card-load {
        grid-column: span 12 !important;
    }

    .app {

        .grid {
            flex-direction: column !important;
        }

        .music-card,
        .music-card-load {
            width: 100%;
        }

        .no-found {
            width: 100%;

            .wrapper {
                margin: 0 20px;
                width: 100% !important;

                .title-no-found {
                    font-size: 200px !important;
                }

                .text-no-found {
                    font-size: 13px !important;
                    line-height: 28px !important;
                }
            }
        }
    }

    .music-release-container {

        .music-release-content {

            .music-release-cover {

                .covers-box {

                    &::-webkit-scrollbar {
                        height: 0px !important;
                    }
                }
            }
        }
    }
}

@import url(./global.css);

.sign-out {
    left: 20px;
    bottom: 20px;
    width: 34px;
    height: 34px;
    position: fixed;
    cursor: pointer;
    border: 1px solid var(--border-color);
    background-color: var(--color-black-20);
}

.button {
    border: none;
    display: flex;
    cursor: pointer;
    padding: 0 16px;
    background: none;
    border-radius: 4px;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: var(--transition);
}

.icon-button {
    width: 34px;
    height: 34px;
    font-size: 20px;
    position: relative;
    color: light-dark(var(--color-black), var(--color-white));

    .bag-count {
        top: -2px;
        right: -4px;
        width: 18px;
        height: 18px;
        display: flex;
        cursor: pointer;
        overflow: hidden;
        position: absolute;
        align-items: center;
        border-radius: 50px;
        justify-content: center;
        color: var(--color-white);
        font: normal 10px var(--font-mono);
        background-color: var(--primary-color);
    }

    .icon-two {
        position: relative;

        svg {
            position: absolute;

            &:nth-child(1) {
                z-index: 1;
            }

            &:nth-child(2) {
                z-index: 2;
                margin-left: -1px;
                margin-top: 10px;
            }
        }
    }
}

.primary-button {
    height: 40px;
    text-decoration: none;
    max-width: max-content;
    min-width: max-content;
    color: var(--color-white);
    line-height: 40px !important;
    font: normal 14px var(--font-mono);
    background-color: var(--color-black);
    border: 1px solid var(--border-color);

    &:hover {
        border-color: var(--primary-color);
        background-color: var(--color-black-28);
    }

    &[disabled] {
        cursor: no-drop;
        color: var(--inactive-color);
        border-color: var(--border-color);
        background-color: var(--color-grey);
    }
}